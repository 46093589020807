export default [
  {
    id: "anfa",
    category: "dev",
    date: "2021",
    title: "Scan JobAuto",
    teaser: "Moteur de recherche cartographique des offres d'emploi du secteur de l'automobile",
    text: "Conception, design & développement d'un moteur de recherche multi-critères d'offres d'emploi dans le secteur de l'automobile basé sur l'API pôle emploi. L'outil comporte un module de visualisation des résultats sous forme cartographique et la possibilité de cibler la recherche via une carte interactive.",
    client: "ANFA - Association Nationale pour la Formation Automobile / Plan d’investissement dans les compétences du Ministère du Travail",
    role: "Conception, design & développement",
    link: "https://scan-jobauto.anfa-auto.fr/",
    slides: 1,
  },
  {
    id: "syndex",
    category: "dev",
    date: "2022",
    title: "Syndex",
    teaser: "Portail de ressources et de formation pour les représentants du personnel en entreprise",
    text: "",
    client: "Syndex",
    role: "Développement",
    link: "https://www.syndex.fr",
    slides: 1,
  },
  {
    id: "gouvernement",
    category: "dev",
    date: "2014",
    title: "Gouvernement.fr",
    teaser: "Direction technique de la refonte du portail gouvernement.fr",
    text: "",
    client: "SIG / Services du premier ministre",
    role: "Direction technique",
    link: false,
    slides: 1,
  },
  {
    id: "cse-le-jeu",
    category: "dev",
    date: "2022",
    title: "CSE le jeu",
    teaser: "Application mobile de formation iOS / Android.",
    text: "",
    client: "Syndex",
    role: "Développement",
    link: false,
    slides: 1,
  },
  {
    id: "rennes-patrimoine",
    category: "carto",
    date: "2018",
    title: "Carte des monuments historiques de Rennes",
    teaser: "Cartographie interactive des bâtiments protégés au titre des monuments historiques",
    text: "Carte interactive à l'échelle du cadastre des bâtiments protégés au titre des monuments historiques sur le territoire de la ville de Rennes, à partir du croisement des données du Ministère de la Culture et du project collaboratif de cartographie en ligne Open Street Map.",
    client: "Projet personnel",
    role: "Conception, design & développement",
    video: "rennes-patrimoine.webm",
    link: "https://rennes-patrimoine.gregoiredavid.fr",
    slides: 3,
  },
  {
    id: "venise",
    category: "carto",
    date: "2018",
    title: "L'empire vénitien en méditerranée au XVe siècle",
    teaser: "Carte vectorielle géoréférencée",
    text: "Carte vectorielle (SVG / D3 JS) géoréférencée des territoires sous contrôle vénitien et des voies de circulation maritimes des navires de la cité-État au XVe sièlce",
    client: "Projet personnel",
    role: "Conception, design & développement",
    link: "https://venise.gregoiredavid.fr",
    slides: 1,
  },
  {
    id: "france-geojson",
    category: "carto",
    date: "2016",
    title: "Atlas GeoJSON",
    teaser: "Cartes de France et de ses subdivisions administratives au format GeoJSON",
    text: "Projet open source proposant, à partir des données de l'IGN, des cartes détaillées des régions, déparements, arrondissements, cantons et communes de France au format ouvert GeoJSON.",
    client: "Projet personnel",
    role: "Conception, design & développement",
    video: "france-geojson.webm",
    link: "https://france-geojson.gregoiredavid.fr",
    slides: 4,
  },
  {
    id: "hebrides",
    category: "carto",
    date: "2016",
    title: "Cartographie des Hébrides Extérieures",
    teaser: "Brochure cartographique imprimée",
    text: "Brochure cartographique imprimée présentant les principaux sites patrimoniaux des îles Hébrides Extérieures en Écosse",
    client: "Projet étudiant",
    role: "Conception & design",
    link: false,
    slides: 4,
  },
  {
    id: "mddf",
    category: "carto",
    date: "2013",
    title: "Cartographie des événements du 8 mars",
    teaser: "Carte interactive et collaborative pour le Ministère des Droits des Femmes",
    text: "Carte interactive et collaborative des événements programmés le 8 mars 2013 à l'occasion de la journée internationale des droits des femmes",
    client: "Ministère des Droits des Femmes",
    role: "Conception, design & développement",
    link: false,
    slides: 4,
  },
  {
    id: "peinture",
    category: "mediation",
    date: "2021",
    title: "Moteur de recherche d'oeuvres d'art (Peinture)",
    teaser: "Base de données de 163 000 tableaux en haute définition",
    text: "Ce projet permet d'effectuer des recherches croisées sur une base de données de 163 000 oeuvres d'arts appartenant au genre de la peinture à l'échelle mondiale. Les oeuvres sont extraites du projet Wikipedia / Wikidata et des contributions des internautes et des institutions participantes. Les images sont pour la plupart disponibles en haute définition et peuvent être explorées en détail via une visionneuse interactive",
    client: "Projet personnel",
    role: "Conception, design & développement",
    link: "https://peinture.gregoiredavid.fr",
    slides: 1,
  },
  {
    id: "lasergrammetrie",
    category: "mediation",
    date: "2020",
    title: "Valorisation de collections en 3D",
    teaser: "Publication de modèles 3D web interactifs à partir de relevés par Lasergrammétrie",
    text: "Exploitation de données brutes 3D collectées par lasergrammétrie, optimisation et publication dans un format léger et interactif adapté au web. Travail en cours.",
    client: "Port-Musée de Douarnenez",
    role: "Infographie 3D & développement",
    link: "https://sketchfab.com/models/b6129bdbb851414f905b1a72cb16ae7b/embed?ui_controls=1&ui_infos=1&ui_inspector=1&ui_stop=1&ui_watermark=1&ui_watermark_link=1",
    slides: 1,
  },
    /*
  {
    id: "brest",
    category: "mediation",
    date: "2021",
    title: "Images du vieux Brest",
    teaser: "Plateforme de photos anciennes géolocalisées de Brest avant-guerre",
    text: "Plateforme de consultation de photos anciennes géolocalisées à partir des collections Gallica / BNF et du musée de Bretagne. Travail en cours.",
    client: "Projet personnel",
    role: "Conception, design & développement",
    link: "https://brest.gregoiredavid.fr",
    slides: 3,
  },
  */
  {
    id: "hent",
    category: "mediation",
    date: "2019",
    title: "Application multimédia d'aide à la visite",
    teaser: "Application smartphone associée à la scénographie via des QR codes",
    text: "Dans le cadre de l'exposition « Hent » au Port-Musée de Douarnenez consacrée au travail du photographe Stéphane Lavoué dans le pays Bigouden, conception d'une application web et smartphone (PWA) proposant des contenus multimédia complémentaires des oeuvres exposées. Enrichissement de la scénographie par l'ajout de cartels munis de QR codes pour accéder directements aux ressources associées à chaque oeuvre via la caméra de son téléphone de portable.",
    client: "Port-Musée de Douarnenez",
    role: "Conception, design & développement",
    link: "https://www.port-musee.org/m/hent",
    slides: 1,
  },
  {
    id: "appli-visite",
    category: "mediation",
    date: "2016",
    title: "Application d'aide à la visite",
    teaser: "Prototype d'application iPad de découverte du patrimoine",
    text: "Prototype d'application iPad de découverte du patrimoine",
    client: "Projet personnel",
    role: "Conception, design & développement",
    link: false,
    slides: 1,
  },
  {
    id: "pointdevue",
    category: "mediation",
    date: "2021",
    title: "Outil de géolocalisation de photographies anciennes",
    teaser: "Application web cartographique collaborative",
    text: "Outil collaboratif proposant la géolocalisation de photographies et de cartes postales anciennes issues de collections en ligne (Gallica / BNF entre autres), en précisant l'angle de vue et la perspective.",
    client: "Projet personnel",
    role: "Conception, design & développement",
    link: "https://pointdevue.gregoiredavid.fr",
    slides: 1,
  },
  {
    id: "phototheque",
    category: "mediation",
    date: "2018",
    title: "Cartes postales anciennes des éditions Villard",
    teaser: "Collection en ligne et moteur de recherche de cartes postales anciennes du Finistère",
    text: "Plateforme de visualisation avec moteur de recherche des cartes postales anciennes des éditions Villard (Quimper), à partir des collections du Musée de Bretagne (Rennes)",
    client: "Projet personnel",
    role: "Conception, design & développement",
    // link: "https://editions-villard.gregoiredavid.fr",
    link: false,
    slides: 3,
  },
  {
    id: "ronde-de-nuit",
    category: "mediation",
    date: "2018",
    title: "Découverte interactive d'un tableau",
    teaser: "Application web interactive de découverte de «la ronde de nuit» de Rembrandt",
    text: "Application web interactive de décryptage de l'oeuvre «la ronde de nuit» du peintre hollandais Rembrandt",
    client: "Projet personnel",
    role: "design & développement",
    link: "https://ronde-de-nuit.gregoiredavid.fr",
    slides: 3,
  },
]
