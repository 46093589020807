import * as React from "react"
import "./layout.css"

export default function Layout({ children }) {
  return (
    <div className="limiter">
      {children}
    </div>
  )
}
      // <script src="/hover.js" />
